export function RankIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="22"
      height="25"
      viewBox="0 0 28 29"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4771 2.12487C14.3305 1.65776 13.6695 1.65776 13.5229 2.12487L13.2412 3.02307C13.1758 3.23154 12.9826 3.3734 12.7641 3.3734H11.8159C11.3358 3.3734 11.1317 3.98425 11.5153 4.27292L12.3122 4.8726C12.4813 4.99986 12.552 5.21983 12.4886 5.42178L12.1914 6.36919C12.0459 6.83295 12.5808 7.21062 12.9691 6.91838L13.6994 6.36888C13.8774 6.23491 14.1226 6.23491 14.3006 6.36888L15.0309 6.91838C15.4193 7.21062 15.9541 6.83295 15.8086 6.36919L15.5114 5.42178C15.448 5.21983 15.5187 4.99986 15.6878 4.8726L16.4847 4.27292C16.8684 3.98425 16.6642 3.3734 16.1841 3.3734H15.2359C15.0174 3.3734 14.8243 3.23154 14.7589 3.02307L14.4771 2.12487ZM12.5 9.66665C11.3954 9.66665 10.5 10.5621 10.5 11.6666V24.5833C10.5 25.6879 11.3954 26.5833 12.5 26.5833H15.5C16.6046 26.5833 17.5 25.6879 17.5 24.5833V11.6666C17.5 10.5621 16.6046 9.66665 15.5 9.66665H12.5ZM20.6667 16.9166C19.5621 16.9166 18.6667 17.8121 18.6667 18.9167V24.5833C18.6667 25.6879 19.5621 26.5833 20.6667 26.5833H23.6667C24.7712 26.5833 25.6667 25.6879 25.6667 24.5833V18.9166C25.6667 17.8121 24.7712 16.9166 23.6667 16.9166H20.6667ZM2.33334 16.5C2.33334 15.3954 3.22877 14.5 4.33334 14.5H7.33334C8.43791 14.5 9.33334 15.3954 9.33334 16.5V24.5833C9.33334 25.6879 8.43791 26.5833 7.33334 26.5833H4.33334C3.22877 26.5833 2.33334 25.6879 2.33334 24.5833V16.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
