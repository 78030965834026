//USER_MENU

import { RewardIcon } from '@/app/components/icons/reward-icon';
import { AwardIcon } from '@/app/components/icons/award-icon';
import { ChatIcon } from '@/app/components/icons/chat-icon';
import { GameIcon } from '@/app/components/icons/game-icon';
import { HomeIcon } from '@/app/components/icons/home-icon';
import { LocationIcon } from '@/app/components/icons/location-icon';
import { RankIcon } from '@/app/components/icons/rank-icon';
import { ShoppingBagsIcon } from '@/app/components/icons/shoppin-bag-icon';
import { ShoppingCartIcon } from '@/app/components/icons/shopping-cart-icon';
import styles from '@/assets/css/styles';

export const USER_MENU = [
  {
    menu: [
      {
        icon: '/icons/bag.svg',
        menu: 'Giveaways',
        link: '/',
      },
      {
        icon: '/icons/location.svg',
        menu: 'How To Play',
        link: '/howtoplay',
      },
    ],
  },
  {
    heading: 'explore',
    menu: [
      {
        icon: '/icons/people.svg',
        menu: 'Participants',
        link: '/participants',
      },
      {
        icon: '/icons/Award 8.svg',
        menu: 'Winners',
        link: '/winners',
      },
      {
        icon: '/icons/feed-rectangle.svg',
        menu: 'Broadcasts',
        link: '/broadcasts',
      },
      {
        icon: '/icons/star.svg',
        menu: 'Star Points',
        link: '/starpoints',
      },
      {
        icon: '/icons/Message.svg',
        menu: 'Social',
        link: '/socialhub',
      },
      {
        icon: '/icons/cart.svg',
        menu: 'Merchandise',
        link: '/merchandise',
      },
      {
        icon: '/icons/rank.svg',
        menu: 'Leaderboards',
        link: '/leaderboards',
      },
    ],
  },
  {
    heading: 'more from a4g',
    menu: [
      {
        icon: '/icons/Award.svg',
        menu: 'Rewards Centre',
        link: '/rewardscentre',
      },
      {
        icon: '/icons/download.svg',
        menu: 'Download Our App',
        link: '/',
      },
      {
        icon: '/icons/announcement.svg',
        menu: 'Newswire',
        link: '/newswire',
      },
      {
        icon: '/icons/help.svg',
        menu: 'Help&Support',
        link: '/help',
      },
    ],
  },
];

//GIVE_AWAYS
export const GIVEAWAYS = {
  'All(100)': [
    {
      img: '/Rectangle 27.png',
      timer: '3.44',
      completion: '',
      category: 'INSTANT WINS',
      title1: '£100 FREE TO ENTER',
      title2: '    + 10 X £10 SITE CREDIT INSTANT WINS',
      price: '£4.00',
      link: '/',
    },
    {
      img: '/Rectangle 27.png',
      timer: '3.44',
      completion: '',
      category: 'INSTANT WINS',
      title1: '£100 FREE TO ENTER',
      title2: '    + 10 X £10 SITE CREDIT INSTANT WINS',
      price: '£4.00',
      link: '/',
    },
    {
      img: '/Rectangle 27.png',
      timer: '3.44',
      completion: '',
      category: 'INSTANT WINS',
      title1: '£100 FREE TO ENTER',
      title2: '    + 10 X £10 SITE CREDIT INSTANT WINS',
      price: '£4.00',
      link: '/',
    },
    {
      img: '/Rectangle 27.png',
      timer: '3.44',
      completion: '',
      category: 'INSTANT WINS',
      title1: '£100 FREE TO ENTER',
      title2: '    + 10 X £10 SITE CREDIT INSTANT WINS',
      price: '£4.00',
      link: '/',
    },
    {
      img: '/Rectangle 27.png',
      timer: '3.44',
      completion: '',
      category: 'INSTANT WINS',
      title1: '£100 FREE TO ENTER',
      title2: '    + 10 X £10 SITE CREDIT INSTANT WINS',
      price: '£4.00',
      link: '/',
    },
    {
      img: '/Rectangle 27.png',
      timer: '3.44',
      completion: '',
      category: 'INSTANT WINS',
      title1: '£100 FREE TO ENTER',
      title2: '    + 10 X £10 SITE CREDIT INSTANT WINS',
      price: '£4.00',
      link: '/',
    },
    {
      img: '/Rectangle 27.png',
      timer: '3.44',
      completion: '',
      category: 'INSTANT WINS',
      title1: '£100 FREE TO ENTER',
      title2: '    + 10 X £10 SITE CREDIT INSTANT WINS',
      price: '£4.00',
      link: '/',
    },
    {
      img: '/Rectangle 27.png',
      timer: '3.44',
      completion: '',
      category: 'INSTANT WINS',
      title1: '£100 FREE TO ENTER',
      title2: '    + 10 X £10 SITE CREDIT INSTANT WINS',
      price: '£4.00',
      link: '/',
    },
  ],
  'Instant Wins': [
    {
      img: '/Rectangle 27.png',
      timer: '3.44',
      completion: '',
      category: 'INSTANT WINS',
      title1: '£100 FREE TO ENTER',
      title2: '    + 10 X £10 SITE CREDIT INSTANT WINS',
      price: '£4.00',
      link: '/',
    },
    {
      img: '/Rectangle 27.png',
      timer: '3.44',
      completion: '',
      category: 'INSTANT WINS',
      title1: '£100 FREE TO ENTER',
      title2: '    + 10 X £10 SITE CREDIT INSTANT WINS',
      price: '£4.00',
      link: '/',
    },
  ],
  Tech: [
    {
      img: '/Rectangle 27.png',
      timer: '3.44',
      completion: '',
      category: 'INSTANT WINS',
      title1: '£100 FREE TO ENTER',
      title2: '    + 10 X £10 SITE CREDIT INSTANT WINS',
      price: '£4.00',
      link: '/',
    },
  ],
  'Newley Added': [
    {
      img: '/Rectangle 27.png',
      timer: '3.44',
      completion: '',
      category: 'INSTANT WINS',
      title1: '£100 FREE TO ENTER',
      title2: '    + 10 X £10 SITE CREDIT INSTANT WINS',
      price: '£4.00',
      link: '/',
    },
  ],
  'Ending Soon': [
    {
      img: '/Rectangle 27.png',
      timer: '3.44',
      completion: '',
      category: 'INSTANT WINS',
      title1: '£100 FREE TO ENTER',
      title2: '    + 10 X £10 SITE CREDIT INSTANT WINS',
      price: '£4.00',
      link: '/',
    },
  ],
  'Starting Soon': [
    {
      img: '/Rectangle 27.png',
      timer: '3.44',
      completion: '',
      category: 'INSTANT WINS',
      title1: '£100 FREE TO ENTER',
      title2: '    + 10 X £10 SITE CREDIT INSTANT WINS',
      price: '£4.00',
      link: '/',
    },
  ],
};

//MENU
export const MENU = [
  {
    icon: <HomeIcon />,
    link: '/',
    class: 'active_menu',
  },
  {
    icon: <ChatIcon />,
    link: '/socialhub',
  },
  {
    icon: <RewardIcon />,
    link: '/rewardscentre',
  },
  {
    icon: <GameIcon />,
    link: '/howtoplay',
  },
];

//SIDEBAR_MENU
export const SIDEBAR_MENU = [
  {
    icon: <ShoppingBagsIcon />,
    link: '/',
  },
  {
    icon: <LocationIcon />,
    link: '/',
  },
  {
    icon: <AwardIcon />,
    link: '/',
  },
  {
    icon: <ShoppingCartIcon />,
    link: '/',
  },
  {
    icon: <RankIcon />,
    link: '/',
  },
];

//LIVE_BROADCAST
export const LIVE_BROADCAST = [
  'https://www.youtube.com/embed/hXwIqwfAAhI?si=UxHwAjoLGScIg_h4',
  'https://www.youtube.com/embed/esAmrWGwOn4?si=I7IETMluZ78M69rj',
  'https://www.youtube.com/embed/hXwIqwfAAhI?si=UxHwAjoLGScIg_h4',
  'https://www.youtube.com/embed/esAmrWGwOn4?si=I7IETMluZ78M69rj',
  'https://www.youtube.com/embed/hXwIqwfAAhI?si=UxHwAjoLGScIg_h4',
  'https://www.youtube.com/embed/esAmrWGwOn4?si=I7IETMluZ78M69rj',
  'https://www.youtube.com/embed/hXwIqwfAAhI?si=UxHwAjoLGScIg_h4',
];

//RECENT_WINNERS

export const RECENT_WINNERS = [
  {
    name: '@randomusername',
    heading: '20 PLAYS OF THE CLAW MACHINE TO WIN PRIZES, CASH AND SITE CREDIT',
    ticketNumber: '12345',
    price: 'GAMING BUNDLE',
  },
  {
    name: '@randomusername',
    heading: '20 PLAYS OF THE CLAW MACHINE TO WIN PRIZES, CASH AND SITE CREDIT',
    ticketNumber: '12345',
    price: 'GAMING BUNDLE',
  },
  {
    name: '@randomusername',
    heading: '20 PLAYS OF THE CLAW MACHINE TO WIN PRIZES, CASH AND SITE CREDIT',
    ticketNumber: '12345',
    price: 'GAMING BUNDLE',
  },
  {
    name: '@randomusername',
    heading: '20 PLAYS OF THE CLAW MACHINE TO WIN PRIZES, CASH AND SITE CREDIT',
    ticketNumber: '12345',
    price: 'GAMING BUNDLE',
  },
  {
    name: '@randomusername',
    heading: '20 PLAYS OF THE CLAW MACHINE TO WIN PRIZES, CASH AND SITE CREDIT',
    ticketNumber: '12345',
    price: 'GAMING BUNDLE',
  },
  {
    name: '@randomusername',
    heading: '20 PLAYS OF THE CLAW MACHINE TO WIN PRIZES, CASH AND SITE CREDIT',
    ticketNumber: '12345',
    price: 'GAMING BUNDLE',
  },
  {
    name: '@randomusername',
    heading: '20 PLAYS OF THE CLAW MACHINE TO WIN PRIZES, CASH AND SITE CREDIT',
    ticketNumber: '12345',
    price: 'GAMING BUNDLE',
  },
  {
    name: '@randomusername',
    heading: '20 PLAYS OF THE CLAW MACHINE TO WIN PRIZES, CASH AND SITE CREDIT',
    ticketNumber: '12345',
    price: 'GAMING BUNDLE',
  },
  {
    name: '@randomusername',
    heading: '20 PLAYS OF THE CLAW MACHINE TO WIN PRIZES, CASH AND SITE CREDIT',
    ticketNumber: '12345',
    price: 'GAMING BUNDLE',
  },
];

//NEWSWIRE

export const NEWSWIRE = [
  {
    title: 'A4G, Version 4 is nearly here!',
    descp:
      'Hey, Josh here! – After a year of hard work, we are finally nearing closer towards launching the fourth version of the All For Giveaways',
    date: '8 August 2023 ',
    comments: 'No Comments',
    link: '/newswire',
  },
  {
    title: 'Share your feedback',
    descp:
      'Help us improve future versions of All For Giveaways by sharing your feedback with our team.',
    date: '8 March 2023',
    comments: 'No Comments',
    link: '/newswire',
  },
  {
    title: 'We are finally back live!',
    descp:
      'We are finally back online with the next version of the All For Giveaways website – Lite Version.',
    date: '10 February 2023',
    comments: 'No Comments',
    link: '/newswire',
  },
  {
    title: 'We are finally back live!',
    descp:
      'We are finally back online with the next version of the All For Giveaways website – Lite Version.',
    date: '10 February 2023',
    comments: 'No Comments',
    link: '/newswire',
  },
  {
    title: 'A4G, Version 4 is nearly here!',
    descp:
      'Hey, Josh here! – After a year of hard work, we are finally nearing closer towards launching the fourth version of the All For Giveaways',
    date: '8 August 2023 ',
    comments: 'No Comments',
    link: '/newswire',
  },
  {
    title: 'Share your feedback',
    descp:
      'Help us improve future versions of All For Giveaways by sharing your feedback with our team.',
    date: '8 March 2023',
    comments: 'No Comments',
    link: '/newswire',
  },
];

//Footer
// about-us
export const ABOUT_US = [
  {
    label: 'Live Competitions',
    link: '/livecompetitions',
  },
  {
    label: 'How To Play',
    link: '/howtoplay',
  },
  {
    label: 'Newswire',
    link: '/newswire',
  },
  {
    label: 'MyA4G',
    link: '/mya4g',
  },
];

// LOTERRY_HISTORY
export const LOTERRY_HISTORY = [
  {
    date: '9 January 2024 23:19',
    tickerNumber: '01',
    user: '@randomusername',
  },
  {
    date: '9 January 2024 23:19',
    tickerNumber: '56',
    user: '@randomusername',
  },
  {
    date: '9 January 2024 23:19',
    tickerNumber: '23',
    user: '@randomusername',
  },
  {
    date: '9 January 2024 23:19',
    tickerNumber: '222',
    user: '@randomusername',
  },
  {
    date: '9 January 2024 23:19',
    tickerNumber: '01',
    user: '@randomusername',
  },
];

//PRODUCT DETAILS
export const PRODUCT_DETAILS = [
  {
    img: '/images/Rectangle 27.png',
    title: '20 PLAYS OF THE CLAW MACHINE TO WIN PRIZES, CASH AND SITE CREDIT',
    ticketNumber: '69',
    price: '2.00',
    quantity: 1,
    total: '2.00',
  },
  {
    img: '/images/Rectangle 27.png',
    title: '20 PLAYS OF THE CLAW MACHINE TO WIN PRIZES, CASH AND SITE CREDIT',
    ticketNumber: '69',
    price: '2.00',
    quantity: 1,
    total: '2.00',
  },
];

//TICKET
export const TICKET = [
  {
    tickets: '333',
    price: '5000 points',
    winner: 'Not Yet Won',
  },
  {
    tickets: '333',
    price: '5000 points',
    winner: 'Not Yet Won',
  },
  {
    tickets: '333',
    price: '5000 points',
    winner: 'Not Yet Won',
  },
  {
    tickets: '333',
    price: '5000 points',
    winner: 'Not Yet Won',
  },
  {
    tickets: '333',
    price: '5000 points',
    winner: 'Not Yet Won',
  },
];

//STAR_POINTS

export const STAR_POINTS = [
  {
    icon: '/star-primary.svg',
    heading: 'Accumilating Star Points',
    descrp:
      'Star Points otherwise referred to as (SP) is our loyalty points currency system, which operates in a very similar way to those of popular high street brands, such as Boots Loyalty Card and Tesco Club card. Every point you earn through our system is equivilant to earning 1p in real money.',
  },
  {
    icon: '/earn.svg',
    heading: 'How To Earn',
    descrp:
      'Points are typically won through our weekly giveaways, but can also be earned through completing various challenges and actions via our platform.',
  },
  {
    icon: '/balance.svg',
    heading: 'Viewing Your Balance',
    descrp:
      'You can view your point balance towards the top of our platform e.g. 100 SP, would mean you have 100 Points ready to spend on your account, which in real currency, would equivilate to £1.',
  },
  {
    icon: '/points.svg',
    heading: 'Spending Your Points',
    descrp: `To use your points, simply enter in to any giveaway of your choice, and proceed through to Checkout. Providing you have sufficient amount of points within your account to enter in to our giveaway, you will be provided with an option on Checkout called "Pay With Star Points". Select this option and click "Complete Purchase" to complete your order utilising your point balance.`,
  },
];

//STAR_POINTS

export const BROADCAST = [
  {
    link: 'https://www.youtube.com/embed/hXwIqwfAAhI?si=UxHwAjoLGScIg_h4',
    heading: 'RANDOM GIVEAWAY TITLE',
    descp:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore',
    date: '8 August 2023',
  },
  {
    link: 'https://www.youtube.com/embed/esAmrWGwOn4?si=I7IETMluZ78M69rj',
    heading: 'RANDOM GIVEAWAY TITLE',
    descp:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore',
    date: '8 August 2023',
  },
  {
    link: 'https://www.youtube.com/embed/hXwIqwfAAhI?si=UxHwAjoLGScIg_h4',
    heading: 'RANDOM GIVEAWAY TITLE',
    descp:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore',
    date: '8 August 2023',
  },
  {
    link: 'https://www.youtube.com/embed/hXwIqwfAAhI?si=UxHwAjoLGScIg_h4',
    heading: 'RANDOM GIVEAWAY TITLE',
    descp:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore',
    date: '8 August 2023',
  },
  {
    link: 'https://www.youtube.com/embed/hXwIqwfAAhI?si=UxHwAjoLGScIg_h4',
    heading: 'RANDOM GIVEAWAY TITLE',
    descp:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore',
    date: '8 August 2023',
  },
  {
    link: 'https://www.youtube.com/embed/hXwIqwfAAhI?si=UxHwAjoLGScIg_h4',
    heading: 'RANDOM GIVEAWAY TITLE',
    descp:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore',
    date: '8 August 2023',
  },
];

export const LEADER_BOARD = [
  {
    position: 1,
    icon: '/images/winners.png',
    email: '@randomusername',
    totalWins: '32',
  },
  {
    position: 2,
    icon: '/images/winners.png',
    email: '@randomusername',
    totalWins: '32',
  },
  {
    position: 3,
    icon: '/images/winners.png',
    email: '@randomusername',
    totalWins: '32',
  },
  {
    position: 4,
    icon: '/images/winners.png',
    email: '@randomusername',
    totalWins: '32',
  },
  {
    position: 5,
    icon: '/images/winners.png',
    email: '@randomusername',
    totalWins: '32',
  },
  {
    position: 6,
    icon: '/images/winners.png',
    email: '@randomusername',
    totalWins: '32',
  },
  {
    position: 7,
    icon: '/images/winners.png',
    email: '@randomusername',
    totalWins: '32',
  },
  {
    position: 8,
    icon: '/images/winners.png',
    email: '@randomusername',
    totalWins: '32',
  },
  {
    position: 9,
    icon: '/images/winners.png',
    email: '@randomusername',
    totalWins: '32',
  },
  {
    position: 10,
    icon: '/images/winners.png',
    email: '@randomusername',
    totalWins: '32',
  },
  {
    position: 10,
    icon: '/images/winners.png',
    email: '@randomusername',
    totalWins: '32',
  },
  {
    position: 11,
    icon: '/images/winners.png',
    email: '@randomusername',
    totalWins: '32',
  },
];

export const OUR_WINNERS = {
  winnersTab: [
    {
      img: '/images/Rectangle 27.png',
      name: 'WINNER NAME',
      price: 'WHAT THEY WON',
    },
    {
      img: '/images/Rectangle 27.png',
      name: 'WINNER NAME',
      price: 'WHAT THEY WON',
    },
    {
      img: '/images/Rectangle 27.png',
      name: 'WINNER NAME',
      price: 'WHAT THEY WON',
    },
    {
      img: '/images/Rectangle 27.png',
      name: 'WINNER NAME',
      price: 'WHAT THEY WON',
    },
    {
      img: '/images/Rectangle 27.png',
      name: 'WINNER NAME',
      price: 'WHAT THEY WON',
    },
    {
      img: '/images/Rectangle 27.png',
      name: 'WINNER NAME',
      price: 'WHAT THEY WON',
    },
  ],
  twentyThree: [],
  twentyTwo: [],
  twentyOne: [],
  twenty: [],
};

// TERMS_CONDITIONS

export const TERMS_CONDITIONS = [
  {
    heading: '1 - Title',
    descp:
      ' empor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
  },
  {
    heading: '1.1 - subTitle',
    descp:
      ' Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
  },
  {
    heading: '1.2 - subTitle',
    descp:
      ' empor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
  },
  {
    heading: '2 - Title',
    descp:
      '  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.  ',
  },
  {
    heading: '2.1 - subTitle',
    descp:
      '  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
  },
  {
    heading: '2.2 - subTitle',
    descp:
      '  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
  },
];

export const PAYMENT_STATUS = [
  {
    heading: 'Order Number',
  },
];
