import { forwardRef } from 'react';
import cn from 'classnames';
import { SpinnerIcon } from '@/app/components/icons/spinner-icon';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
  disabled?: boolean;
  className?: string;
  size?: 'size_sm' | 'size_normal' | 'size_medium' | 'size_lg' |'size_auto';
  radius?: string;
  height?: string;
  // onClick?: string,
  variant?: 'btn_primary' | 'btn_primary2' | 'btn_white' | 'btn_dark' | 'icon';
  children?: React.ReactNode;
}

const variantClasses = {
  btn_primary: 'bg-brand text-white',
  btn_primary2: 'apply bg-brand text-dark',
  btn_white: 'bg-white dark:bg-brand-gray_2 text-dark',
  btn_dark: 'apply bg-brand-dark_2 text-brand-gray_1',
  icon: 'transition-colors duration-200 text-dark-800 hover:text-dark-900 dark:hover:text-light-600',
};
const sizeClasses = {
  size_lg:
    'w-[350px] md:w-[300px] h-[40px] md:h-[44px] rounded-xl md:rounded-2xl',
  size_normal: 'w-[150px] lg:w-[180px] h-[40px] md:h-[44px]',
  size_medium: 'w-[160px] h-[43px]',
  size_sm: 'w-full md:w-[145px] h-[43px] rounded-xl md:rounded-2xl',
  size_auto: 'w-auto h-[43px]',
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      isLoading,
      disabled,
      children,
      size = 'size_auto',
      radius,
      height,
      variant = 'btn_primary',
      ...props
    },
    ref,
  ) => (
    <button
      ref={ref}
      className={cn(
        `transition-fill-colors font-stratos font-bold text-base items-center flex justify-center uppercase text-center ${height ? height : 'h-[40px] md:h-[44px]'} duration-200`,
        isLoading || disabled
          ? 'pointer-events-none cursor-not-allowed'
          : 'pointer-events-auto cursor-pointer',
        disabled ? 'opacity-70' : 'opacity-100',
        variantClasses[variant],
        className,
        sizeClasses[size],
        radius ? radius : 'rounded-[10px]',
      )}
      {...props}
    >
      {isLoading && <SpinnerIcon className="h-auto w-5 animate-spin" />}
      {children}
    </button>
  ),
);

Button.displayName = 'Button';
export default Button;
