export function LocationIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
    width="22"
    height="25"
      viewBox="0 0 28 26"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.89 7.65948C16.0051 7.65948 17.7197 5.94485 17.7197 3.82974C17.7197 1.71463 16.0051 0 13.89 0C11.7748 0 10.0602 1.71463 10.0602 3.82974C10.0602 5.94485 11.7748 7.65948 13.89 7.65948Z"
        fill="currentColor"
      />
      <path
        d="M27.4941 17.11C27.4941 17.11 16.9957 22.2544 16.405 22.3496C15.1665 22.6736 13.547 22.7307 12.2133 22.3115C11.4892 22.0638 0 16.7289 0 16.7289C0 16.7289 0 19.2439 0 19.5869C0 19.9299 10.8223 24.5598 11.7369 24.96C12.9563 25.4935 15.7762 25.4363 16.9385 24.979C17.5482 24.7313 27.3226 20.2919 27.4941 20.0823C27.6465 19.8536 27.4941 17.11 27.4941 17.11Z"
        fill="currentColor"
      />
      <path
        d="M0.609729 15.6428C1.08606 15.8524 11.9275 20.8063 12.118 20.8635C13.547 21.2445 14.4806 21.3017 16.3098 20.8635C16.5384 20.8063 26.4652 16.1573 26.9035 15.9667C27.5132 15.6238 27.8371 14.0804 26.9035 13.4898C25.9889 12.9182 16.9385 8.78359 16.6527 8.68832C16.3669 8.59306 15.5476 8.61211 15.5476 8.61211C15.5476 8.61211 15.5286 14.7664 15.5095 15.2999C15.4905 15.8334 14.1758 16.0239 14.1758 16.0239C14.1758 16.0239 12.5181 15.7381 12.5181 15.2999C12.5181 14.8616 12.5181 8.63116 12.5181 8.63116C12.5181 8.63116 11.7369 8.55495 11.3749 8.63116C11.0129 8.70738 1.18133 13.1659 0.628782 13.4898C-0.171462 13.9471 0.152447 15.4332 0.609729 15.6428ZM5.60173 13.5279C6.63061 13.5279 7.46897 14.0804 7.46897 14.7664C7.46897 15.4523 6.63061 16.0048 5.60173 16.0048C4.57284 16.0048 3.73449 15.4523 3.73449 14.7664C3.73449 14.0804 4.57284 13.5279 5.60173 13.5279Z"
        fill="currentColor"
      />
    </svg>
  );
}
