import * as React from 'react';
export const HomeIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.86708 6.20695C11.2006 3.785 12.8674 2.57403 14.7709 2.5042C14.9236 2.4986 15.0764 2.4986 15.2291 2.5042C17.1327 2.57403 18.7994 3.785 22.1329 6.20695C25.4664 8.62889 27.1332 9.83986 27.7878 11.6286C27.8404 11.7721 27.8876 11.9175 27.9294 12.0645C28.4513 13.8964 27.8146 15.8558 26.5413 19.7746L24.39 26.3955C24.0482 27.4476 23.0679 28.1598 21.9617 28.1598C20.5515 28.1598 19.4084 27.0167 19.4084 25.6065V22.1704C19.4084 20.8472 18.3358 19.7746 17.0126 19.7746H12.9874C11.6642 19.7746 10.5916 20.8472 10.5916 22.1704V25.6065C10.5916 27.0167 9.44846 28.1598 8.03831 28.1598C6.93215 28.1598 5.9518 27.4476 5.60998 26.3955L3.45869 19.7746C2.1854 15.8558 1.54876 13.8964 2.07056 12.0645C2.11242 11.9175 2.15966 11.7721 2.21217 11.6286C2.8668 9.83986 4.53356 8.62889 7.86708 6.20695Z"
        fill="currentColor"
      />
    </svg>
  );
};

// icon:home | Ionicons https://ionicons.com/ | Ionic Framework

export function HomeIcon2(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 512 512" fill="currentColor" {...props}>
      <path d="M261.56 101.28a8 8 0 00-11.06 0L66.4 277.15a8 8 0 00-2.47 5.79L63.9 448a32 32 0 0032 32H192a16 16 0 0016-16V328a8 8 0 018-8h80a8 8 0 018 8v136a16 16 0 0016 16h96.06a32 32 0 0032-32V282.94a8 8 0 00-2.47-5.79z" />
      <path d="M490.91 244.15l-74.8-71.56V64a16 16 0 00-16-16h-48a16 16 0 00-16 16v32l-57.92-55.38C272.77 35.14 264.71 32 256 32c-8.68 0-16.72 3.14-22.14 8.63l-212.7 203.5c-6.22 6-7 15.87-1.34 22.37A16 16 0 0043 267.56L250.5 69.28a8 8 0 0111.06 0l207.52 198.28a16 16 0 0022.59-.44c6.14-6.36 5.63-16.86-.76-22.97z" />
    </svg>
  );
}
