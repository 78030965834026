// icon:bxs-shopping-bags | Boxicons https://boxicons.com/ | Atisa
import * as React from 'react';

export function ShoppingBagsIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="22"
      height="25"
      viewBox="0 0 32 36"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_dd_22_321)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.8461 9.83052C15.8461 10.0721 15.6571 10.268 15.424 10.268C15.1908 10.268 15.0018 10.0721 15.0018 9.83052V3.73623C15.0018 1.6804 16.6232 0 18.6069 0C20.5906 0 22.212 1.68037 22.212 3.73623V9.83052C22.212 10.0721 22.023 10.268 21.7899 10.268C21.5567 10.268 21.3677 10.0721 21.3677 9.83052V3.73623C21.3677 2.16347 20.1245 0.874999 18.6069 0.874999C17.0893 0.874999 15.8461 2.16343 15.8461 3.73623V9.83052ZM15.9219 19.4172V27.4064C15.9219 27.6174 15.8785 27.8183 15.8006 28H26.7465C27.1227 28 27.4271 27.8595 27.678 27.5701C27.9283 27.2815 28.0315 26.9496 27.9917 26.5644L26.0952 8.21984C26.0767 8.04077 25.934 7.90671 25.7575 7.90671H22.968V9.83052C22.968 10.5046 22.4403 11.0516 21.7899 11.0516C21.1394 11.0516 20.6117 10.5046 20.6117 9.83052V7.90671H16.6021V9.83052C16.6021 10.5046 16.0744 11.0516 15.424 11.0516C14.7736 11.0516 14.2458 10.5046 14.2458 9.83052V7.90671H11.4563C11.2798 7.90671 11.1371 8.04078 11.1186 8.21984L10.5243 13.9682H10.9199C11.153 13.9682 11.342 14.1641 11.342 14.4057V14.6869C12.1282 13.375 13.1673 12.6451 14.8446 12.134C15.1294 12.0473 15.43 12.2869 15.3775 12.6323L14.9868 15.7688H15.8943C16.4293 15.7688 16.8654 16.2208 16.8654 16.7752V18.9797C16.8654 19.2213 16.6764 19.4172 16.4432 19.4172H15.9219ZM15.0776 19.4172V27.4064C15.0776 27.732 14.819 28 14.5049 28H11.342V19.4172H15.0776ZM15.8943 16.6438C15.9632 16.6438 16.0211 16.7038 16.0211 16.7752V18.5422H11.342L11.3438 16.6438H15.8943ZM11.7251 15.7688C12.3532 14.4639 13.0629 13.7263 14.4606 13.1848L14.1388 15.7688H11.7251ZM9.52338 14.8432V28H10.4977V14.8432H9.52338ZM8.6791 19.4172H4.94347V27.4064C4.94347 27.732 5.20209 28 5.51621 28H8.6791V19.4172ZM8.67726 16.6438H4.12679C4.05792 16.6438 4 16.7038 4 16.7752V18.5422H8.6791L8.67726 16.6438ZM5.56047 13.1848L5.88231 15.7688H8.29596C7.66789 14.464 6.95814 13.7262 5.56047 13.1848Z"
          fill="currentColor"
        />
      </g>
      {/* <defs>
        <filter
          id="filter0_dd_22_321"
          x="0"
          y="0"
          width="32"
          height="36"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_22_321"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_22_321"
            result="effect2_dropShadow_22_321"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_22_321"
            result="shape"
          />
        </filter>
      </defs> */}
    </svg>
  );
}
