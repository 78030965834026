export const ShoppingCartIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      width="22"
      height="25"
      viewBox="0 0 25 28"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.1208 4.97294L17.9474 7.80522L22.138 8.00753V1.07133C22.138 0.724517 21.8779 0.435508 21.5311 0.406607L15.1729 0.00199565C14.8261 -0.0269052 14.5082 0.262103 14.4793 0.608913L14.3347 2.66087L16.1266 2.74757C17.2826 2.80538 18.1786 3.81691 18.1208 4.97294Z"
        fill="currentColor"
      />
      <path
        d="M16.5023 7.71849L16.6468 4.88621C16.6757 4.51049 16.3867 4.22149 16.0399 4.19259L14.2481 4.10588L10.202 3.87468C9.85515 3.84578 9.53724 4.13478 9.50833 4.48159L9.33493 7.34278L14.0169 7.57398L16.5023 7.71849Z"
        fill="currentColor"
      />
      <path
        d="M24.739 9.85721C24.5945 9.6549 24.3633 9.5393 24.1321 9.5393L6.38702 8.64337L5.89571 5.40648C5.83791 5.00187 5.4622 4.68396 5.05758 4.68396H0.838063C0.37565 4.68396 -6.10352e-05 5.05967 -6.10352e-05 5.52208C-6.10352e-05 5.9845 0.37565 6.36021 0.838063 6.36021H4.27726L6.53153 21.2152C6.64713 21.9089 6.96504 22.4869 7.39855 22.9493C6.90724 23.4117 6.61823 24.0764 6.61823 24.7989C6.61823 26.244 7.80316 27.4 9.2193 27.4C10.6354 27.4 11.8204 26.2151 11.8204 24.7989C11.8204 24.481 11.7626 24.1631 11.647 23.8741H17.7451C17.6294 24.1631 17.5716 24.481 17.5716 24.7989C17.5716 26.244 18.7566 27.4 20.1727 27.4C21.6178 27.4 22.7738 26.2151 22.7738 24.7989C22.7738 23.3539 21.5889 22.1979 20.1727 22.1979H9.65282C8.93029 22.1979 8.32338 21.6776 8.20778 20.9551L8.00547 19.6257H18.93C21.4444 19.6257 23.6119 17.8338 24.0454 15.3195L24.8836 10.5219C24.9414 10.3196 24.8836 10.0595 24.739 9.85721ZM9.24821 25.7238C8.72799 25.7238 8.32338 25.3192 8.32338 24.7989C8.32338 24.2787 8.72799 23.8741 9.24821 23.8741C9.76842 23.8741 10.173 24.2787 10.173 24.7989C10.173 25.3192 9.73952 25.7238 9.24821 25.7238ZM21.1553 24.8278C21.1553 25.3481 20.7507 25.7527 20.2305 25.7527C19.7103 25.7527 19.3057 25.3481 19.3057 24.8278C19.3057 24.3076 19.7103 23.903 20.2305 23.903C20.7218 23.903 21.1553 24.3076 21.1553 24.8278Z"
        fill="currentColor"
      />
    </svg>
  );
};
