export const RewardIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
    return (
      <svg
        width="27"
        height="27"
        viewBox="0 0 27 27"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M14.1688 5.85922C13.9526 5.17193 13.0473 5.17193 12.8311 5.85922C12.5176 6.85574 11.6072 7.55585 10.5525 7.55585C10.2262 7.55585 9.98263 7.75655 9.88362 8.07132C9.78401 8.38801 9.86537 8.72073 10.1481 8.93295C10.9844 9.56057 11.3216 10.6581 11.0094 11.6506C10.8942 12.017 11.0321 12.3307 11.274 12.5122C11.5118 12.6907 11.8097 12.717 12.0826 12.5122C12.9258 11.8794 14.074 11.8794 14.9172 12.5122C15.1901 12.717 15.488 12.6907 15.7259 12.5122C15.9677 12.3307 16.1057 12.017 15.9904 11.6506C15.6783 10.6581 16.0155 9.56057 16.8517 8.93295C17.1345 8.72073 17.2159 8.38801 17.1163 8.07132C17.0172 7.75655 16.7737 7.55585 16.4474 7.55585C15.3927 7.55585 14.4822 6.85574 14.1688 5.85922Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.65618 10.125C3.65618 4.68845 8.06337 0.28125 13.4999 0.28125C18.9365 0.28125 23.3437 4.68845 23.3437 10.125C23.3437 10.6054 23.3092 11.078 23.2426 11.5404C23.1001 11.919 22.9361 12.287 22.7523 12.643C21.2766 15.5004 18.5169 17.5851 15.2405 18.1365C14.675 18.2316 14.0936 18.2812 13.5 18.2812C12.8058 18.2812 12.1283 18.2134 11.4735 18.0842C8.26471 17.4512 5.58281 15.3407 4.16962 12.4887C4.01706 12.1809 3.87929 11.8643 3.75724 11.5401C3.69064 11.0778 3.65618 10.6053 3.65618 10.125ZM11.2213 5.35288C11.9329 3.0907 15.067 3.0907 15.7785 5.35288C15.88 5.6754 16.1595 5.86835 16.4474 5.86835C18.8201 5.86835 19.6785 8.92129 17.8647 10.2826C17.6147 10.4702 17.4974 10.8176 17.6002 11.1442C17.9488 12.2526 17.5016 13.2894 16.7388 13.8619C15.9721 14.4373 14.8458 14.5685 13.9043 13.8619C13.6613 13.6795 13.3386 13.6795 13.0956 13.8619C12.1541 14.5685 11.0278 14.4373 10.261 13.8619C9.49822 13.2894 9.05108 12.2526 9.3997 11.1442C9.50244 10.8176 9.38514 10.4702 9.13519 10.2826C7.32132 8.92129 8.17979 5.86835 10.5525 5.86835C10.8404 5.86835 11.1199 5.6754 11.2213 5.35288Z"
          fill="currentColor"
        />
        <path
          d="M3.6715 14.9234C3.57177 15.0922 3.46671 15.2742 3.35357 15.4703L1.29935 19.0283C1.0457 19.4675 0.811155 19.8736 0.675932 20.2098C0.543482 20.5391 0.376675 21.11 0.745294 21.6386C1.11858 22.1738 1.71778 22.2055 2.0729 22.1878C2.43244 22.17 2.88951 22.08 3.3805 21.9833L4.27067 21.8082C4.76416 21.7112 5.05696 21.6552 5.2742 21.6403C5.37342 21.6334 5.42669 21.638 5.4529 21.6424C5.46494 21.6444 5.47205 21.6468 5.47205 21.6468L5.47535 21.6484L5.47834 21.6505C5.47834 21.6505 5.48396 21.6554 5.49174 21.6648C5.50866 21.6853 5.53927 21.7292 5.58297 21.8185C5.67865 22.0141 5.77656 22.2957 5.93925 22.7716L6.23267 23.63C6.39447 24.1035 6.54509 24.5444 6.70939 24.8647C6.87166 25.1811 7.19874 25.6841 7.84889 25.7398C8.4909 25.7948 8.90199 25.3648 9.12094 25.0855C9.34447 24.8003 9.57891 24.3941 9.83244 23.9548L11.8454 20.4683C11.9652 20.2608 12.0747 20.0711 12.1734 19.8968C11.8268 19.8589 11.4844 19.8064 11.1469 19.7398C8.07868 19.1345 5.42924 17.3703 3.6715 14.9234Z"
          fill="currentColor"
        />
        <path
          d="M23.154 15.1601C23.2051 15.2482 23.2577 15.3393 23.3121 15.4336L25.3874 19.0283C25.6411 19.4675 25.8756 19.8736 26.0108 20.2098C26.1433 20.5391 26.3101 21.11 25.9415 21.6386C25.5682 22.1738 24.969 22.2055 24.6138 22.1878C24.2543 22.17 23.7972 22.08 23.3063 21.9833L22.4161 21.8082C21.9226 21.7112 21.6298 21.6552 21.4125 21.6403C21.3133 21.6334 21.2601 21.638 21.2338 21.6424C21.2218 21.6444 21.2147 21.6468 21.2147 21.6468L21.2114 21.6484L21.2098 21.6494L21.2084 21.6505C21.2084 21.6505 21.2028 21.6554 21.195 21.6648C21.1781 21.6853 21.1475 21.7292 21.1038 21.8185C21.0081 22.0141 20.9102 22.2957 20.7475 22.7716L20.4541 23.63C20.2923 24.1035 20.1416 24.5444 19.9774 24.8647C19.8151 25.1811 19.488 25.6841 18.8379 25.7398C18.1958 25.7948 17.7848 25.3648 17.5658 25.0855C17.3423 24.8003 17.1079 24.3941 16.8543 23.9548L14.8413 20.4682C14.7281 20.2721 14.624 20.0919 14.5296 19.9255C14.8638 19.8973 15.1943 19.8555 15.5205 19.8006C18.6288 19.2775 21.3326 17.5702 23.154 15.1601Z"
          fill="currentColor"
        />
      </svg>
    );
  };
  